var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"c-so-view has-local-controls",class:{
        'c-so-view--no-frame': !_vm.hasFrame,
        'has-complex-content': _vm.complexContent,
        'is-missing': _vm.domainObject.status === 'missing'
    }},[_c('div',{staticClass:"c-so-view__header"},[_c('div',{staticClass:"c-object-label",class:{
                 classList: _vm.classList,
                 'is-missing': _vm.domainObject.status === 'missing'
             }},[_c('div',{staticClass:"c-object-label__type-icon",class:_vm.cssClass},[_c('span',{staticClass:"is-missing__indicator",attrs:{"title":"This item is missing"}})]),_vm._v(" "),_c('div',{staticClass:"c-object-label__name"},[_vm._v("\n                "+_vm._s(_vm.domainObject && _vm.domainObject.name)+"\n            ")])]),_vm._v(" "),_c('context-menu-drop-down',{attrs:{"object-path":_vm.objectPath}})],1),_vm._v(" "),_c('div',{staticClass:"c-so-view__local-controls c-so-view__view-large h-local-controls c-local-controls--show-on-hover"},[_c('button',{staticClass:"c-button icon-expand",attrs:{"title":"View Large"},on:{"click":_vm.expand}})]),_vm._v(" "),_c('div',{staticClass:"is-missing__indicator",attrs:{"title":"This item is missing"}}),_vm._v(" "),_c('object-view',{ref:"objectView",staticClass:"c-so-view__object-view",attrs:{"object":_vm.domainObject,"show-edit-view":_vm.showEditView,"object-path":_vm.objectPath}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }