var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"c-table c-table--sortable c-list-view"},[_c('table',{staticClass:"c-table__body"},[_c('thead',{staticClass:"c-table__header"},[_c('tr',[_c('th',{staticClass:"is-sortable",class:{
                        'is-sorting': _vm.sortBy === 'model.name',
                        'asc': _vm.ascending,
                        'desc': !_vm.ascending
                    },on:{"click":function($event){_vm.sort('model.name', true)}}},[_vm._v("\n                    Name\n                ")]),_vm._v(" "),_c('th',{staticClass:"is-sortable",class:{
                        'is-sorting': _vm.sortBy === 'type.name',
                        'asc': _vm.ascending,
                        'desc': !_vm.ascending
                    },on:{"click":function($event){_vm.sort('type.name', true)}}},[_vm._v("\n                    Type\n                ")]),_vm._v(" "),_c('th',{staticClass:"is-sortable",class:{
                        'is-sorting': _vm.sortBy === 'model.persisted',
                        'asc': _vm.ascending,
                        'desc': !_vm.ascending
                    },on:{"click":function($event){_vm.sort('model.persisted', false)}}},[_vm._v("\n                    Created Date\n                ")]),_vm._v(" "),_c('th',{staticClass:"is-sortable",class:{
                        'is-sorting': _vm.sortBy === 'model.modified',
                        'asc': _vm.ascending,
                        'desc': !_vm.ascending
                    },on:{"click":function($event){_vm.sort('model.modified', false)}}},[_vm._v("\n                    Updated Date\n                ")])])]),_vm._v(" "),_c('tbody',_vm._l((_vm.sortedItems),function(item){return _c('list-item',{key:item.objectKeyString,attrs:{"item":item,"object-path":item.objectPath}})}))])])}
var staticRenderFns = []

export { render, staticRenderFns }