<template>
<div class="c-toolbar__separator"></div>
</template>

<script>
export default {
    props: {
        options: {
            type: Object,
            required: true
        }
    }
}
</script>
