var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('th',_vm._g({style:({ width: _vm.columnWidth + 'px', 'max-width': _vm.columnWidth + 'px'}),attrs:{"draggable":_vm.isEditing},on:{"mouseup":_vm.sort}},_vm.isEditing ? {
        dragstart: _vm.columnMoveStart,
        drop: _vm.columnMoveEnd,
        dragleave: _vm.hideDropTarget,
        dragover: _vm.dragOverColumn
    } : {}),[_c('div',{staticClass:"c-telemetry-table__headers__content",class:[
            _vm.isSortable ? 'is-sortable' : '',
            _vm.isSortable && _vm.sortOptions.key === _vm.headerKey ? 'is-sorting' : '',
            _vm.isSortable && _vm.sortOptions.direction].join(' ')},[_c('div',{staticClass:"c-telemetry-table__resize-hitarea",on:{"mousedown":_vm.resizeColumnStart}}),_vm._v(" "),_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }